
import Vue from 'vue';
import Component from 'vue-class-component';
import ListDocTransactional from '@/wms/infrastructure/ui/docs/ListDocTransactional.vue';
import { Order } from '@/courier/domain/order/Order';
import { TypeDocument } from '@/settings/domain/options/TypeDocument';
import { currencyFormatter } from '@/core/formatter/CurrencyFormatter';

@Component({
  name: 'TypeDocumentWhreceipt',
  components: { ListDocTransactional }
})
export default class TypeDocumentWhreceipt extends Vue {
  optionstypeDocument: TypeDocument = new TypeDocument(
    this.$i18n.t('menu.warehouseReceipt').toString(),
    'WR',
    3,
    true,
    `${this.$t('docs.warehouseReceipt')}`
  );
  //GETTERS
  get fields() {
    return [
      { field: 'number', label: `#`, sortable: true },
      { field: 'type', label: this.$t('general.typeDocument'), sortable: true },
      { field: 'concept', label: this.$t('general.concept'), sortable: true },
      { field: 'date', label: this.$t('general.date'), sortable: true },
      { field: 'customerName', label: this.$t('general.entity'), sortable: true },
      { field: 'warehouseName', label: this.$t('general.warehouse'), sortable: true },
      {
        field: 'total',
        label: this.$t('general.total'),
        sortable: true,
        formatFn: (value: any) => currencyFormatter.format({ value: value, currency: 'USD' })
      },
      { field: 'actions', label: this.$t('general.actions') }
    ];
  }
  items: Order[] = [];
  isLoading = false;
  fullPage = true;

  refreshItems(object: Order) {
    this.items.push(object);
  }
}
